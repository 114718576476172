.pending-users-container {
  text-align: center;
  padding: 20px;
}
.pending-users-container >h1,p {
  padding-top: 10px;
}

.go-to-booking-button {
  background-color: #3498db; /* Blue color, change as needed */
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.go-to-booking-button:hover {
  background-color: #2980b9; /* Darker blue color on hover, change as needed */
}

.go-back-arrow {
  position: fixed;
  top: 10px;
  left: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #007bff;
}

/* Add this CSS in your stylesheets or style tag */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #FFFFFF;
}

.custom-table th, .custom-table td {
  border: none;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.custom-table th {
  background-color: #F9FAFB;
  color: grey !important;
}

.custom-table td {
  color: #475467;
}

/* Red color for 'Fail' status */
.has-text-danger {
  color: red;
}

/* Green color for 'Success' status */
.has-text-success {
  color: green;
}

.toggle {
  color: black !important;
}

/* Add this to your CSS file */
.react-smart-data-table th .icon-up:before {
  content: "↑"; /* Upward arrow */
}

.custom-table th .icon-down:before {
  content: "↓"; /* Downward arrow */
}



