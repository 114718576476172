/* Logout.css */
.logout-container {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.logout-button {
  background-color: rgb(134, 34, 34);
  color: antiquewhite;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.logout-icon {
  margin-right: 8px;
}

.checked {
  outline: none;
  background-color: #010808;
}

