

.admin-dashboard-container {
  margin: 20px;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.admin-table th, .admin-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.admin-table th {
  background-color: #a17070;
}

.admin-table button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.admin-table button:hover {
  background-color: #45a049;
}

.admin-dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

h1 {
  color: #5382a1;
  font-size: 24px;
  margin-right: 20px;
}

.dashboard-button {
  background-color: #3498db;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dashboard-button:hover {
  background-color: #2980b9;
}

.assign-admin-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.email-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.assign-admin-button {
  background-color: #27ae60;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.assign-admin-button:hover {
  background-color: #219653;
}

.assigned-header {
  margin-top: 30px;
}

