
.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.menu-item {
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  color: #ffffff;
}

.menu-item img,
.menu-item .icon {
  width: 26px;
  height: 26px;
}

.menu-item span {
  font-size: 16px;
  line-height: 24px;
}

.menu-container {
  padding: 1rem 0;
}

.menu-item:hover {
  background-color: #1a202c;
}


.customize-table .rdt_TableHeader {
  background-color: #4a87c5;
}


.custom-table th, .custom-table td {
  padding: 18px;
  text-align: left;
}

.custom-table td {
  border-bottom: 1px solid #EAECF0;
}

.custom-row:last-child {
  border-bottom: none;
}

.custom-widthl {
  width: 340px !important;
}

.system-admin-row td {
  background-color: #F7FFFF;
  text-align: center;
  align-items: center;
}

.role-dropdown {
  appearance: none;
  background-color: #ffffff;
  border: none;
  padding: 5px 8px 5px 8px;
  cursor: pointer;
  width: 100%;
  background-repeat: no-repeat;
  background-position: right 5px center;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.role-text {
  padding: 7px 10px;
  background-color: #f0f0f0;
  cursor: pointer;
}

/* .role-text:hover {
  background-color: #e0e0e0;
} */

@media (max-width: 320px) {
  .custom-width {
    width: 280px !important;
  }

  .custom-widthl {
    width: 280px !important;
  }

  .overflow-x-auto {
    overflow-x: auto;
    max-width: 280px !important;
  }

  .name {
    width: 200px;
  }

  .custom-widthB {
    width: 280px !important;
  }
  .control {
    padding-right: var(--container-padding);
    padding-left: 6%;
    margin-left: 'auto';
    margin-right: 'auto';
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .custom-width {
    width: 315px !important;
  }

  .custom-widthl {
    width: 315px !important;
  }

  .overflow-x-auto {
    overflow-x: auto;
    max-width: 315px !important;
  }

  .name {
    width: 250px;
  }

  .custom-widthB {
    width: 315px !important;
  }
  .control {
    padding-right: var(--container-padding);
    padding-left: 6%;
    margin-left: 'auto';
    margin-right: 'auto';
  }
}

@media (min-width: 376px) and (max-width: 397px)  {
  .custom-width {
    width: 335px !important;
  }
  .control {
    padding-right: var(--container-padding);
    padding-left: 6.35%;
    margin-left: 'auto';
    margin-right: 'auto';
  }

  .custom-widthl {
    width: 335px !important;
  }

  .overflow-x-auto {
    overflow-x: auto;
    max-width: 335px !important;
  }
  
  .name {
    width:250px;
  }

  .custom-widthB {
    width: 335px !important;
  }
}

@media (min-width: 397px) and (max-width: 427px) {
  .custom-width {
    width: 365px !important;
  }

  .custom-widthl {
    width: 365px !important;
  }

  .overflow-x-auto {
    overflow-x: auto;
    width: 365px;
  }
  .control {
    padding-right: var(--container-padding);
    padding-left: 5.65%;
    margin-left: 'auto';
    margin-right: 'auto';
  }

  .name {
    width:250px;
  }

  .custom-widthB {
    width: 365px !important;
  }
}

@media (min-width: 427px) and (max-width: 467px) {
  .custom-width {
    width: 365px !important;
  }

  .custom-widthl {
    width: 365px !important;
  }

  .overflow-x-auto {
    overflow-x: auto;
    width: 365px;
  }

  .name {
    width:260px;
  }

  .custom-widthB {
    width: 365px !important;
  }
  .control {
    padding-right: var(--container-padding);
    padding-left: 7%;
    margin-left: 'auto';
    margin-right: 'auto';
  }
}
@media screen and (max-width: 767px) {
  .custom-switch-bar {
    width: 58px !important; 
  }

  .swipeable-area {
    touch-action: pan-y;
  }

  .dropdown {
    padding: 8px;
    margin-bottom: 10px;
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 5px;
    color: #101828;
    width: 90%;
  }
  
}

.modal-container {
  background: white;
  padding: 50px;
}

.modal-content {
  margin-bottom: 20px;
}

.modal-close {
  cursor: pointer;
  background-color: rgb(11, 3, 3);
  border: none;
  border-radius: 5px;
  position: absolute;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #349999;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-switch-bar1 {
  display: flex;
  margin-top: 12px;
  width: 40px;
  height: 1.5rem;
  align-items: center;
  border-radius: 0.75rem;
  transition: all 0.3s ease;
}

.custom-switch-circle {
  width: 20px;
  height: 1.25rem;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

@media (max-width: 600px) {
  .custom-switch-bar1 {
    display: flex;
    margin-top: 12px;
    width: 40px;
    height: 1.5rem;
    align-items: center;
    border-radius: 0.95rem;
    transition: all 0.3s ease;
  }

  .custom-switch-bar {
      width: 35px; 
      height: 1.2rem; 
  }

  .custom-switch-circle {
      width: 16px;
      height: 1rem;
  }
}

@media (min-width: 467px) and (max-width: 527px) {
  .custom-width {
    width: 450px !important;
  }

  .custom-widthl {
    width: 450px !important;
  }

  .overflow-x-auto {
    overflow-x: auto;
    width: 450px;
  }
  .name {
    width:250px;
  }

  .custom-widthB {
    width: 450px !important;
  }
}

@media (min-width: 527px) and (max-width: 667px) {
  .custom-width {
    width: 550px !important;
  }

  .custom-widthl {
    width: 550px !important;
  }

  .overflow-x-auto {
    overflow-x: auto;
    width: 550px;
  }
  .name {
    width:250px;
  }

  .custom-widthB {
    width: 550px !important;
  }
}

@media (min-width: 667px) and (max-width: 767px) {
  .custom-width {
    width: 650px !important;
  }

  .custom-widthl {
    width: 650px !important;
  }

  .overflow-x-auto {
    overflow-x: auto;
    width: 650px;
  }
  .name {
    width:250px;
  }

  .custom-widthB {
    width: 650px !important;
  }
}

@media (min-width: 1110px) and (max-width: 1230px) {
  .table-right {
    padding-right: 3rem;
  }
}

@media (max-width: 640px) {
  .dataTable-cell {
    padding: 4px;
  }
  
  .dataTable-content {
    font-size: 12px;
  }

  .debug {
    padding-top: 0px;
  }
}

@media (max-width: 600px) { 
  .custom-switch-bar {
    width: '30px';
    height: '1.2rem';
  }
  .main-content {
    width: 100%;
    overflow-x: auto;
  }
  
  .main-content-company {
    width: 100%;
    overflow-x: auto;
  }
 }

.table-container {
  width: 100%;
  overflow-x: auto;
}

.main-content {
  width: 87%;
  overflow-x: auto;
}

.modal-content {
  max-height: 85vh;
  overflow-y: auto;
}

.main-content-company {
  width: 87%;
  overflow-x: auto;
}
.light-mode {
  background-color: #f0f0f0; /* Light background */
  color: #333; /* Dark text for light background */
}

.dark-mode {
  background-color: #333; /* Dark background */
  color: #f0f0f0; /* Light text for dark background */
}


.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.cell-truncate {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
}

.cell-truncate:hover::after {
  content: attr(data-title);
  position: absolute;
  white-space: pre;
  overflow: visible;
  bottom: 100%;
  left: 0;
  background-color: black;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  z-index: 20;
  width: max-content;
  max-width: 300px;
}

.tooltip {
  position: absolute;
  z-index: 1000;
  width: max-content;
  max-width: 260px;
  padding: 8px;
  color: white;
  background: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  display: block;
}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
}

.tooltipbeep {
  position: absolute;
  top: 30%;
  left: 50;
  z-index: 1000;
  width: max-content;
  max-width: 260px;
  padding: 8px;
  color: white;
  background: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  display: block;
}


.tooltipbeep::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
}

.tooltipCode {
  position: absolute;
  top: 45%;
  left: 50;
  z-index: 1000;
  width: max-content;
  max-width: 260px;
  padding: 8px;
  color: white;
  background: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  display: block;
}

.tooltipCode::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
}

.tooltipVolume {
  position: absolute;
  top: 96%;
  left: 50;
  z-index: 1000;
  width: max-content;
  max-width: 300px;
  padding: 8px;
  color: white;
  background: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  display: block;
}

.tooltipVolume::after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
}
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  display: inline-block;
}
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: inline-block;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.tooltip-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  white-space: normal;
  min-width: auto; 
  width: 220px;
  font-size: 14px;
  z-index: 100;
  transition: all 0.3s ease;
  text-align: center;
}

.tooltip-content::after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.form-group {
  position: relative;
}
.container {
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .tooltip {
    top: 36%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tooltipCode {
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tooltipVolume {
    top: 79%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tooltipbeep {
    top: 46%;
    left: 50%;
    transform: translateX(-50%);
  }
  .truncated-text {
    max-width: 98px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1800px) {
  .tooltip {
    top: 36%;
    left: 43%;
    transform: translateX(-50%);
  }
  .tooltipbeep {
    top: 36%;
    left: 59%;
    transform: translateX(-50%);
  }
  .tooltipCode {
    top: 58%;
    left: 43%;
    transform: translateX(-50%);
  }
  .tooltipVolume {
    top: 60%;
    left: 59%;
    transform: translateX(-50%);
  }
}

/* Large screens (desktops) */
@media only screen and (min-width: 1800px) {
  .tooltip {
    top: 27%;
    left: 45%;
    transform: translateX(-50%);
  }
  .tooltipbeep {
    top: 27%;
    left: 57%;
    transform: translateX(-50%);
  }
  .tooltipCode {
    top: 45%;
    left: 44%;
    transform: translateX(-50%);
  }
  .tooltipVolume {
    top: 47%;
    left: 57%;
    transform: translateX(-50%);
  }
  .container {
    width: calc(100% - 120px);
    margin: 0 60px;
    max-width: 100%;
  }
  .contain {
    margin: auto;
  }
}

@media (max-width: 768px) {
  #eventPieChartSmall {
    display: block;
  }
  #eventPieChartLarge {
    display: none;
  }
}

canvas {
  transition: none; 
}
.md-hidden.control .dropdown {
  width: 150px; /* Set to desired width */
}

/* Default container styles */
/* Default container styles */











